<template>
  <component :is="'GSwitch'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-switch">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GSwitch } from '@twentyfourg/grimoire';

export default {
  name: 'PassportSwitch',
  inheritAttrs: false,
  extends: GSwitch,
  components: {
    GSwitch,
  },
  props: {
    rounded: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.passport-switch {
  ::v-deep {
    .track {
      background-color: transparent;
      border: 1px solid rgba(#222, 0.5);
      &.checked {
        background-color: inherit;
        border-color: #222;
        .thumb {
          background-color: #222;
          border: none;
        }
      }
      .thumb {
        background-color: transparent;
        border: 1px solid #222;
      }
    }
    .label.after {
      white-space: nowrap;
    }
  }
}
</style>
